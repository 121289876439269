import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Grid, Card, Text, CardTitle } from './styled';
import { Layout, SEO } from '../../layout';
import { Section } from '../../components/styled';

interface Resource {
  id: string;
  title: ReactNode;
  url: string;
  description?: string;
}

const resources: Resource[] = [
  {
    id: 'nextFrontier',
    title: 'The Next Frontier | The Data-Driven Dining Experience',
    description:
      'While lockdowns will eventually end, the clean, intuitive digital experiences restaurants diners are familiar with, and even expect, will continue to set the benchmark. Our explainer delves into how to meet those new expectations.',
    url: 'https://fidel.uk/blog/the-data-driven-dining-experience',
  },
  {
    id: 'dataAndCustomerExperience',
    title: (
      <>
        How to start turning data into a better{' '}
        <span className="nowrap">customer experience</span>
      </>
    ),
    description:
      'For retailers who want to create loyalty, encourage repeat visits and drive more revenue, focusing on customer experience is a no-brainer. But its success all comes down to one thing: data.',
    url: 'https://insights.fidel.uk/good-data-great-experiences-one-pager?utm_campaign=2020-Q1-Beginner%27s%20Guide%20to%20Loyalty-Industry-Global&utm_source=Fidel-WS&utm_medium=Resources',
  },
  {
    id: 'stackingUpDataSources',
    title: 'How do different data sources stack up?',
    description:
      'Account-linking and card-linking deliver different types of data. Find out what you need for your loyalty programme.',
    url: 'https://fidel.uk/blog/how-do-different-data-sources-stack-up',
  },
  {
    id: 'cheatSheet',
    title: 'Card-linking cheat sheet',
    description:
      'Card-linking can revolutionise your loyalty offering… but is it right for you? This cheat sheet explains how card-linking works, so you can sound like an expert in seconds.',
    url: 'https://insights.fidel.uk/beginners-guide-cheat-sheet?utm_campaign=2020-Q1-Beginner%27s%20Guide%20to%20Loyalty-Industry-Global&utm_source=Fidel-WS&utm_medium=Resources',
  },
  {
    id: 'beginnersGuide',
    title: 'The beginner’s guide to card-linked loyalty',
    description:
      'Thinking about card-linking, but not sure if it’s the right fit for your business? Our comprehensive eBook weighs up the options.',
    url: 'https://insights.fidel.uk/beginners-guide-loyalty?utm_campaign=2020-Q1-Beginner%27s%20Guide%20to%20Loyalty-Industry-Global&utm_source=Fidel-WS&utm_medium=Resources',
  },
  {
    id: 'whatIsCL',
    title: 'What is card-linking?',
    description:
      'Payment cards are a rich source of valuable transaction data - but it’s difficult to access. Card-linking can help cut time, resource and cost. Here’s how.',
    url: 'https://fidel.uk/blog/what-is-card-linking',
  },
  {
    id: 'omnichannel',
    title:
      'Post-COVID-19, omnichannel will be the only way to save the High Street',
    description:
      'The current crisis is hitting High Street retailers harder than ever before. To get shoppers back in-store and spending once isolation ends, you need a joined-up strategy that rewards them wherever they spend.',
    url: 'https://insights.fidel.uk/omnichannel-saves-high-street-explainer?utm_campaign=2020-Q1-Beginner%27s%20Guide%20to%20Loyalty-Industry-Global&utm_source=Fidel-WS&utm_medium=Resources',
  },
];

const Resources: React.FC = () => {
  const { allFile } = useStaticQuery(assetsQuery);
  const assets = allFile.edges.map(e => e.node);

  const pageDescription =
    'Breaking down the basics of Fidel API, our products, and the payments ecosystem.';

  return (
    <Layout>
      <SEO title="Resources" description={pageDescription} />
      <Section centered maxWidth={680}>
        <h1>Resources</h1>
        <p>{pageDescription}</p>
      </Section>
      <Grid>
        {resources.map(({ id, title, description, url }) => {
          const image = assets.find(asset => asset.name === id);

          return (
            <Card key={id} to={url}>
              {image && (
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt=""
                />
              )}
              <Text>
                <CardTitle>{title}</CardTitle>
                <p>{description}</p>
              </Text>
            </Card>
          );
        })}
      </Grid>
    </Layout>
  );
};

const assetsQuery = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "resources" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default Resources;
