import styled from 'styled-components';

import { colours, breakpoints } from '../../../components/styled/variables';
import { Link } from '../../../components';
import { Section } from '../../../components/styled';

export const Grid = styled(Section)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (min-width: ${breakpoints.tabletPortrait}) {
    padding-top: 0;
    > * {
      width: calc(100% / 2 - 25px);
    }
  }
`;

export const Card = styled(Link)`
  margin-bottom: 34px;
  border: none;

  ::after {
    display: none;
  }

  @media (min-width: ${breakpoints.tabletPortrait}) {
    margin-bottom: 20px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  color: ${colours.primary};
`;

export const CardTitle = styled.h2`
  font-size: 22px;
`;
